import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Confirm from "./Confirm.jsx";
import User from "./User.jsx";
import { useQuery } from "react-query";
import { send, sendForm } from "../lib/com.js";
import { useQueryClient } from "react-query";

const EditUser = () => {
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const user = useQuery(["users", id], () => send("/admin/user/" + id));

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendForm("/admin/update-user/" + id, e.target);
      queryClient.invalidateQueries("users");
      history.push("/users");
    } catch (err) {
      alert(err);
    }
  };

  return user.data ? (
    <>
      <h2 className="mb-5 font-weight-light">{user.data.namn}</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-5">
          <User {...user.data} />
        </div>
        <Confirm label="Uppdatera" back={"/users"} />
      </form>
    </>
  ) : null;
};

export default EditUser;
