import { useMutation, useQueryClient } from "react-query";
import { useEffect, useRef, useCallback } from "react";

export const useOptimistic = (
  cacheKey,
  asyncFn,
  optimisticFn,
  dependencies
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(asyncFn);

  return useCallback(async (...vars) => {
    const oldData = queryClient.getQueryData(cacheKey, { exact: true });
    let result;
    try {
      queryClient.setQueryData(cacheKey, optimisticFn(oldData, ...vars));
      result = await mutation.mutateAsync(...vars);
    } catch (err) {
      queryClient.setQueryData(cacheKey, oldData);
      throw err;
    } finally {
      queryClient.invalidateQueries(cacheKey);
    }
    return result;
  }, dependencies);
};

export const useMounted = () => {
  const mount = useRef(false);

  useEffect(() => {
    mount.current = true;

    return () => {
      mount.current = false;
    };
  }, []);

  return mount;
};
