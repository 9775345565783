import React from 'react';

const User = e =>
  <div className="form-row">
    <div className="col-sm-6">

      <div className="form-group">
        <label htmlFor="namn">Företagsnamn: <small>(Obligatorisk)</small></label>
        <input className="form-control" maxLength={50} name="namn" type="text" id="namn"
          required defaultValue={e.namn} />
      </div>

      <div className="form-group">
        <label htmlFor="org-nr">Org.nr: <small>(Obligatorisk)</small></label>
        <input className="form-control" maxLength={11} name="orgnr" type="text" id="org-nr"
          pattern="\s*\d{6}-\d{4}\s*" required defaultValue={e.orgnr}
          placeholder="XXXXXX-XXXX" />
      </div>

      <div className="form-group">
        <label htmlFor="postadress">Postadress:</label>
        <input className="form-control" maxLength={50} name="postadress" type="text" id="postadress"
          defaultValue={e.postadress} />
      </div>

      <div className="form-group">
        <label htmlFor="postnummer">Postnummer:</label>
        <input className="form-control" maxLength={5} name="postnummer" type="text" id="postnummer"
          pattern="\s*\d{5}\s*" defaultValue={e.postnummer} />
      </div>

      <div className="form-group">
        <label htmlFor="ort">Ort:</label>
        <input className="form-control" maxLength={30} name="ort" type="text" id="ort"
          defaultValue={e.ort} />
      </div>

    </div>
    <div className="col-sm-6">

      <div className="form-group">
        <label htmlFor="bg-nr">Bankgironummer: <small>(Obligatorisk)</small></label>
        <input className="form-control" maxLength={9} name="bgnr" type="text" id="bg-nr"
          pattern="\s*\d{3}\d?-\d{4}\s*" defaultValue={e.bgnr} placeholder="XXX(X)-XXXX" />
      </div>

      <div className="form-group">
        <label htmlFor="kontakt">Kontaktperson:</label>
        <input className="form-control" maxLength={50} name="kontakt" type="text" id="kontakt"
          defaultValue={e.kontakt} />
      </div>

      <div className="form-group">
        <label htmlFor="telefon">Telefonnummer:</label>
        <input className="form-control" maxLength={20} name="telefon" type="tel" id="telefon"
          defaultValue={e.telefon} />
      </div>

      <div className="form-group">
        <label htmlFor="epost">Epost:</label>
        <input className="form-control" maxLength={50} name="epost" type="email" id="epost"
          defaultValue={e.epost} />
      </div>
    </div>
  </div>;

export default User;