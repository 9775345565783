import React from 'react';
import { Link } from 'react-router-dom';

const Confirm = ({ label, spinner, back = '/', disabled }) =>
  <div className="form-row">
    <div className="col-sm-auto">
      <div className="form-group">
        <button className="btn btn-primary" type="submit" disabled={disabled}>
          {
            spinner 
            ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            : null
          }
          <span>{label}</span>
        </button>
      </div>
    </div>
    <div className="col-sm-auto">
      <div className="form-group">
        <Link to={back}>
          <button className="btn btn-secondary" type="button">
            Tillbaka
          </button>
        </Link>
      </div>
    </div>
  </div>
;

export default Confirm;
