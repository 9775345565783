import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NewOrder from './NewOrder.jsx';
import OrderList from './OrderList.jsx';

const Orders = () => (
  <Switch>
    <Route exact path="/new">
      <NewOrder />
    </Route>
    <Route exact path="/">
      <OrderList />
    </Route>
    <Route path="/">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default Orders;
