import React, { useState } from "react";
import { send } from "../lib/com";
import { useQuery } from "react-query";
import "./css/Cart.scss";

const Cart = ({ children }) => {
  const products = useQuery("products", () =>
    send(`/products?min=0&max=100000`).catch(alert)
  );

  const [cart, setCart] = useState([]);

  const change = (evt) => {
    const prod = products.data.find((p) => p.id === +evt.target.value);
    setCart([prod]);
  };

  const pris = cart[0]?.pris ?? products.data?.[0]?.pris ?? 0;

  return (
    <div className="cart">
      <h3 className="mb-5 font-weight-light">Produkter</h3>
      {products.data?.length ? (
        <>
          <table className="table table-borderless mb-5">
            <thead>
              <tr>
                <th>Produkt</th>
                <th className="text-right">Summa exkl. moms</th>
              </tr>
            </thead>
            <tbody>
              {
                <tr>
                  <td className="align-baseline">
                    <select
                      className="custom-select w-auto bg-white d-inline-block item"
                      data-array
                      required
                      name="produkt"
                      data-num
                      onChange={change}
                    >
                      {products.data.map((p) => (
                        <option key={p.id} value={p.id}>{`${p.namn} ${
                          p.ansl
                        } anslutningar${p.mex ? " + Mex" : ""}`}</option>
                      ))}
                    </select>
                  </td>
                  <td className="text-right align-baseline">{pris} kr</td>
                </tr>
              }
            </tbody>
          </table>
          <p>
            <strong>
              Totalbelopp exkl. moms:&nbsp;
              {pris}&nbsp;kr
            </strong>
          </p>
          {children}
        </>
      ) : (
        <i>Sortiment saknas</i>
      )}
    </div>
  );
};

export default Cart;
