import React from "react";
import { useHistory } from "react-router-dom";
import { send, sendForm } from "../lib/com.js";
import Cart from "./Cart.jsx";
import Confirm from "./Confirm.jsx";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";

const NewOrder = () => {
  const customers = useQuery("customers", () => send("/customers"));

  const history = useHistory();
  const queryClient = useQueryClient();

  const submit = async (e) => {
    e.preventDefault();
    try {
      await sendForm("/order", e.target);
      queryClient.invalidateQueries("order");
      history.push("/");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <main>
      <h2 className="mb-5 font-weight-light">Skapa ärende</h2>

      <form className="login mx-auto" onSubmit={submit}>
        <div className="form-row">
          <div className="col-sm-6 form-group">
            <label>Kund:</label>
            <select
              className="custom-select form-control mb-4"
              name="kund"
              id="select"
              required
              data-num
            >
              {customers.data?.map(({ namn, id }) => (
                <option key={namn} value={id}>
                  {namn}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="starttid">Startdatum:</label>
            <input
              className="form-control"
              name="starttid"
              type="date"
              id="starttid"
              defaultValue={dayjs().format("YYYY-MM-DD")}
              data-date
              required
              pattern="\d{4}-\d\d-\d\d"
            />
          </div>
        </div>

        <Cart>
          <div className="mt-5">
            <Confirm label="Skapa" />
          </div>
        </Cart>
      </form>
    </main>
  );
};

export default NewOrder;
