import React from "react";
import { useHistory } from "react-router-dom";
import Confirm from "./Confirm.jsx";
import User from "./User.jsx";
import { sendForm } from "../lib/com.js";

const Setup = () => {
  const history = useHistory(),
    onSubmit = async (e) => {
      e.preventDefault();
      try {
        await sendForm("/authenticate", e.target);
        alert("Kontot kommer att valideras av administratör inom kort.");
        history.push("/");
      } catch (err) {
        alert(err);
      }
    };

  return (
    <>
      <h2 className="mb-5">Företagsuppgifter</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-5">
          <User />
        </div>
        <Confirm label="Spara" back="/" />
      </form>
    </>
  );
};

export default Setup;
