import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import EditUser from './EditUser.jsx';
import UserList from './UserList.jsx';

const Users = () =>
  <Switch>
    <Route exact path="/users/edit/:id">
      <EditUser />
    </Route>
    <Route exact path="/users">
      <UserList />
    </Route>
    <Route path="/users">
      <Redirect to="/" />
    </Route>
  </Switch>

export default Users;
