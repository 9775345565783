import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Profile from './Profile.jsx';
import Splash from './Splash.jsx';
import AdminContext from './AdminContext.jsx';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient();

const App = () => {
  const session = sessionStorage.getItem('namn');
  const admin = sessionStorage.getItem('admin') === 'true';
  const [user, setUser] = useState(session);

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AdminContext.Provider value={admin}>
          {
            user 
            ? <Profile setUser={setUser} />
            : <Splash setUser={setUser} />
          }
        </AdminContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  );
};

export default App;