import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Confirm from "./Confirm.jsx";
import Customer from "./Customer.jsx";
import { sendForm } from "../lib/com.js";
import { useMounted } from "../lib/hooks.js";
import { useQueryClient } from "react-query";

const NewCustomer = () => {
  const history = useHistory();
  const [spinner, setSpinner] = useState();
  const mounted = useMounted();
  const queryClient = useQueryClient();

  const onSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      const [pass, msg] = await sendForm("/new-customer", e.target);
      if (pass) {
        queryClient.invalidateQueries("customers");
        history.push("/customers");
      } else alert(msg);
    } catch (err) {
      alert(err);
    }
    mounted.current && setSpinner(false);
  };

  return (
    <>
      <h2 className="mb-5 font-weight-light">Ny kund</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-5">
          <Customer />
        </div>
        <Confirm spinner={spinner} label="Skapa" back="/customers" />
      </form>
    </>
  );
};

export default NewCustomer;
