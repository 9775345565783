import { send } from "../lib/com";
import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useOptimistic } from "../lib/hooks";
import update from "immutability-helper";

const CustomerList = () => {
  const customers = useQuery("customers", () =>
    send("/customers").catch(alert)
  );

  const del = useOptimistic(
    "customers",
    (id) => send("/delete-customer/" + id),
    (old, _, i) => update(old, { $splice: [[i, 1]] })
  );

  const deleteCustomer = (id, namn, i) => {
    if (window.confirm(`Bekräfta för att ta bort ${namn}`))
      del(id, i).catch(alert);
  };

  return (
    <main className="customer-list">
      <h2 className="mb-5 font-weight-light">Kunder</h2>
      <div className="slim-button mb-3">
        <Link to="/customers/new">+</Link>
      </div>
      <nav>
        <ul className="ul list-group">
          {customers.data?.map(({ namn, id }, i) => (
            <li
              className="list-group-item d-flex mb-2 border-0 align-items-center"
              key={namn}
            >
              <Link className="customer" to={`/customers/edit/${id}`}>
                {namn}
              </Link>
              <button
                className="slim-button ml-auto"
                onClick={() => deleteCustomer(id, namn, i)}
              >
                &times;
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </main>
  );
};

export default CustomerList;
