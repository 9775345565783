import React from "react";
import { Link, useHistory } from "react-router-dom";
import { sendForm } from "../lib/com";
import { useQueryClient } from "react-query";

const Login = ({ setUser }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { name, admin, status } = await sendForm("/login", e.target);
      console.log(status);
      switch (status) {
        case "authenticated":
          alert("Ert konto kommer att valideras av administratör inom kort");
          break;
        case "authorized":
          queryClient.clear();
          sessionStorage.setItem("namn", name);
          sessionStorage.setItem("admin", "" + admin);
          console.log(name);
          setUser(name);
          break;
        default:
          history.push("/setup");
          break;
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <main>
      <div className="row">
        <div className="col-sm-auto mx-auto">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="namn">Användarnamn:</label>
              <input
                className="form-control"
                maxLength={20}
                name="namn"
                type="text"
                id="namn"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Lösenord:</label>
              <input
                className="form-control"
                name="password"
                type="password"
                id="password"
                required
              />
            </div>
            <div className="form-group">
              <button className="btn btn-primary" type="submit">
                Logga in
              </button>
            </div>
            <div className="mb-3">
              <Link to="/signup">Skapa användare</Link>
            </div>
            <div>
              <small>
                <Link to="/activate">Återställ lösenord</Link>
              </small>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Login;
