import React, { useContext } from "react";
import { Link, Switch, Route } from "react-router-dom";
import Orders from "./Orders.jsx";
import Users from "./Users.jsx";
import Customers from "./Customers.jsx";
import Products from "./Products.jsx";
import Logout from "./Logout.jsx";
import EditSelf from "./EditSelf.jsx";
import AdminContext from "./AdminContext.jsx";
import { useQuery } from "react-query";
import { send } from "../lib/com.js";
import "./css/Profile.scss";

const Profile = ({ setUser }) => {
  const admin = useContext(AdminContext);
  const user = useQuery(["users", true], () => send("/user"));

  return (
    <>
      <header>
        <nav className="align-items-baseline navbar navbar-expand-sm navbar-light bg-light">
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {user.data && <span className="navbar-brand">{user.data.namn}</span>}

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav w-100">
              {admin ? (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/user">
                      Mina uppgifter
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/users">
                      Användare
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/products">
                      Produkter
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/user">
                      Mina uppgifter
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Ärenden
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/customers">
                      Kunder
                    </Link>
                  </li>
                </>
              )}
              <li className="nav-item ml-sm-auto">
                <Link className="nav-link" to="/logout">
                  Logga ut
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <Switch>
        <Route exact path="/logout">
          <Logout setUser={setUser} />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route exact path="/user">
          <EditSelf setUser={setUser} />
        </Route>
        <Route path="/customers">
          <Customers />
        </Route>
        {admin ? (
          <Route path="/">
            <Users />
          </Route>
        ) : (
          <Route path="/">
            <Orders />
          </Route>
        )}
      </Switch>
    </>
  );
};

export default Profile;
