const { REACT_APP_API_BASE_PATH } = process.env;

const validateNumber = (number) =>
  Number.isFinite(number) && Number.isInteger(number) && number >= 0;

export const send = (path, body, contentType = "text/plain") =>
  new Promise(async (res, rej) => {
    const response = await fetch(`${REACT_APP_API_BASE_PATH}${path}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": contentType,
      },
      body,
    });

    const { success, info } = await response.json();
    return success ? res(info) : rej(info);
  });

const parseInput = ({ value, checked, type, dataset, required }) => {
  if (type === "checkbox" || type === "radio") return checked;

  const trimmed = value.trim();

  if (required && !trimmed.length)
    throw "Formuläret innehåller ett eller flera tomma obligatoriska fält.";

  if (type === "number" || dataset.num) {
    const number = +trimmed;

    if (!validateNumber(number))
      throw "Formuläret innehåller ett eller flera numreriska fält som inte är positiva heltal.";

    return number;
  } else if (type === "date" || dataset.date) {
    const date = trimmed.match(/\d{4}-\d\d-\d\d/)[0];

    if (!date)
      throw "Formuläret innehåller ett eller flera datumfält som inte har formatet ÅÅÅÅ-MM-DD.";

    return date;
  }
  return trimmed;
};

export const sendForm = (url, form) => {
  const elements = [...form.elements].filter(
    (e) =>
      !e.dataset.transient && (e.tagName === "INPUT" || e.tagName === "SELECT")
  );

  try {
    const data = elements.reduce((acc, em) => {
      const parsed = parseInput(em);

      return {
        ...acc,
        [em.name]:
          em.name in acc
            ? [].concat(acc[em.name], parsed)
            : em.dataset.array
            ? [parsed]
            : parsed,
      };
    }, {});

    return send(url, JSON.stringify(data), "application/json");
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};
