import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Login.jsx';
import Signup from './Signup.jsx';
import Activate from './Activate.jsx';
import Setup from './Setup.jsx';

const Splash = ({ setUser }) =>
  <>
    <header>
      <div className="jumbotron bg-transparent">
        <h1 className="display-4 font-weight-light text-center">Förmån Finans</h1>
      </div>
    </header>

    <Switch>
      <Route exact path="/">
        <Login setUser={setUser} />
      </Route>
      <Route exact path="/activate">
        <Activate />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route exact path="/setup">
        <Setup />
      </Route>
      <Route path="/">
        <Redirect to="/" />
      </Route>
    </Switch>
  </>

export default Splash;