import React from "react";
import { useHistory } from "react-router-dom";
import Confirm from "./Confirm.jsx";
import User from "./User.jsx";
import { useQuery } from "react-query";
import { send, sendForm } from "../lib/com.js";
import { useQueryClient } from "react-query";
import update from "immutability-helper";

const EditSelf = ({ setUser }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const asset = useQuery(["users", true], () => send("/user"));

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const name = await sendForm("/update-user", e.target);
      queryClient.setQueryData(
        ["users", true],
        update(asset.data, {
          namn: { $set: name },
        })
      );
      queryClient.invalidateQueries("users");
      sessionStorage.setItem("namn", name);
      setUser(name);
      history.push("/");
    } catch (err) {
      alert(err);
    }
  };

  return asset.data ? (
    <>
      <h2 className="mb-5 font-weight-light">{asset.data?.namn}</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-5">{asset.data && <User {...asset.data} />}</div>
        <Confirm label="Uppdatera" back="/" />
      </form>
    </>
  ) : null;
};

export default EditSelf;
