import { send } from "../lib/com";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./css/OrderList.scss";
import pdf from "../allmänna-avtalsvillkor-hyra.pdf";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useOptimistic } from "../lib/hooks";
import update from "immutability-helper";
import * as utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const OrderList = () => {
  const [{ from, to }, setRange] = useState({
    from: dayjs().date(1).format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });

  const orders = useQuery(["order", from, to], () =>
    send(`/orders?from=${from}&to=${to}`).catch(alert)
  );

  const del = useOptimistic(
    ["order", from, to],
    (id) => send("/delete-order/" + id),
    (old, _, i) => update(old, { $splice: [[i, 1]] })
  );

  const clear = useOptimistic(
    ["order", from, to],
    () => send("/delete-orders"),
    () => []
  );

  const filter = (e) => {
    e.preventDefault();
    const { elements } = e.target;
    setRange({
      from: elements.namedItem("from").value,
      to: elements.namedItem("to").value,
    });
  };

  const deleteOrders = async () => {
    if (
      orders.data?.length &&
      window.confirm(`Är du säker på att du vill ta bort alla ärenden?`)
    )
      clear().catch(alert);
  };

  const deleteOrder = async (order, i) => {
    if (
      window.confirm(
        `Bekräfta för att ta bort ärende för ${order.cust.namn} från ${dayjs(
          order.tid
        ).format("YYYY-MM-DD")}`
      )
    )
      del(order.id, i).catch(alert);
  };

  return (
    <main className="order-list">
      <h2 className="mb-5 font-weight-light">Ärenden</h2>
      <form className="form" onSubmit={filter}>
        <div className="form-row mb-5">
          <div className="col-sm-5 form-group">
            <label htmlFor="from">fr.o.m</label>
            <input
              id="from"
              type="date"
              className="form-control"
              pattern="\d{4}-\d\d-\d\d"
              defaultValue={from}
            />
          </div>
          <div className="col-sm-5 form-group">
            <label htmlFor="to">t.o.m</label>
            <input
              id="to"
              type="date"
              className="form-control"
              pattern="\d{4}-\d\d-\d\d"
              defaultValue={to}
            />
          </div>
          <div className="col-sm-2 d-flex align-items-end form-group">
            <button className="form-control btn btn-primary" type="submit">
              Filtrera
            </button>
          </div>
        </div>
      </form>
      <div className="d-flex form-group align-items-center justify-content-between">
        <div className="slim-button">
          <Link to="/new">+</Link>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="form-control btn btn-secondary"
            type="button"
            onClick={deleteOrders}
          >
            Radera alla
          </button>
        </div>
      </div>

      <ul className="list-group borderless" id="accordion" role="tablist">
        {orders.data?.map((order, i) => {
          const { id: orderId } = order;

          return (
            <li className="list-group-item border-0 card mb-2" key={orderId}>
              <div
                className="bg-white card-header d-flex align-items-center border-0"
                role="tab"
                id={"heading" + orderId}
              >
                <div>
                  <a
                    data-toggle="collapse"
                    href={"#collapse" + orderId}
                    aria-expanded="false"
                    aria-controls={"collapse" + orderId}
                  >
                    {order.cust.namn}
                  </a>
                  <br />
                  <small>{dayjs(order.tid).format("YYYY-MM-DD")}</small>
                </div>
                <button
                  className="slim-button ml-auto"
                  onClick={() => deleteOrder(order, i)}
                >
                  &times;
                </button>
              </div>

              <div
                className="collapse"
                id={"collapse" + orderId}
                role="tabpanel"
                aria-labelledby={"heading" + orderId}
              >
                <div className="card-body">
                  <div className="card-text">
                    <div className="row">
                      <p className="col-sm-3 text-right">
                        <small>Avtalsnr:</small>
                      </p>
                      <p className="col-sm-9">{order.avtalsnr}</p>
                    </div>
                    <div className="row">
                      <p className="col-sm-3 text-right">
                        <small>Starttid:</small>
                      </p>
                      <p className="col-sm-9">
                        {dayjs(order.starttid).format("YYYY-MM-DD")}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <p className="col-sm-3 text-right">
                      <small>Handlingar:</small>
                    </p>
                    <ul className="col-sm-9 mb-3">
                      <li>
                        <a href={`/contract/${orderId}`}>dokument.pdf</a>
                      </li>
                      <li>
                        <a download="allmänna-villkor.pdf" href={pdf}>
                          allmänna-villkor.pdf
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </main>
  );
};

export default OrderList;
