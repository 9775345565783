import React, { useState } from "react";
import Confirm from "./Confirm.jsx";
import { useHistory } from "react-router-dom";
import { sendForm } from "../lib/com.js";

const Activate = () => {
  const [valid, setValid] = useState();
  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendForm("/activate", e.target);
      history.push("/");
      alert("Användaren än nu aktiverad");
    } catch (err) {
      alert(err);
    }
  };

  const onInput = (e) => {
    const { password, repeatPassword } = e.target.form.elements,
      p1 = password.value,
      p2 = repeatPassword.value;
    setValid(p1.length && p1 === p2);
  };

  return (
    <main>
      <div className="row">
        <div className="col-sm-auto mx-auto">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="namn">Användarnamn:</label>
              <input
                className="form-control"
                maxLength={20}
                name="namn"
                type="text"
                id="namn"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="activation">Aktiveringskod (4 siffror):</label>
              <input
                className="form-control"
                name="activation"
                type="tel"
                maxLength={4}
                pattern="\d{4}"
                required
                id="activation"
                onInput={onInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Lösenord:</label>
              <input
                className="form-control"
                name="password"
                type="password"
                required
                id="password"
                onInput={onInput}
              />
            </div>
            <div className="form-group">
              <label htmlFor="repeat-password">Repetera lösenord:</label>
              <input
                className={
                  "form-control" + (valid ? " text-success" : " text-danger")
                }
                name="repeatPassword"
                type="password"
                id="repeat-password"
                required
                data-transient
                onInput={onInput}
              />
            </div>
            <Confirm label="Aktivera användare" disabled={!valid} />
          </form>
        </div>
      </div>
    </main>
  );
};

export default Activate;
