import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

const { REACT_APP_API_BASE_PATH } = process.env;

const Logout = ({ setUser }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  useEffect(() => {
    fetch(`${REACT_APP_API_BASE_PATH}/logout`, { method: "POST" })
      .then(() => {
        queryClient.clear();
        sessionStorage.removeItem("namn");
        sessionStorage.removeItem("admin");
        setUser(null);
        history.push("/");
      })
      .catch(alert);
  }, [history, queryClient, setUser]);

  return null;
};

export default Logout;
