import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NewCustomer from "./NewCustomer.jsx";
import EditCustomer from "./EditCustomer.jsx";
import CustomerList from "./CustomerList.jsx";

const Customers = () => (
  <Switch>
    <Route exact path="/customers/edit/:id">
      <EditCustomer />
    </Route>
    <Route exact path="/customers/new">
      <NewCustomer />
    </Route>
    <Route exact path="/customers">
      <CustomerList />
    </Route>
    <Route path="/customers">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default Customers;
