import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Confirm from "./Confirm.jsx";
import Customer from "./Customer.jsx";
import { useQuery } from "react-query";
import { send, sendForm } from "../lib/com.js";
import { useMounted } from "../lib/hooks.js";
import { useQueryClient } from "react-query";

const EditCustomer = () => {
  const { id } = useParams();
  const history = useHistory();
  const [spinner, setSpinner] = useState();
  const mounted = useMounted();
  const queryClient = useQueryClient();

  const customer = useQuery(["customers", id], () => send("/customer/" + id));

  const onSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      const [pass, msg] = await sendForm("/update-customer/" + id, e.target);
      if (pass) {
        queryClient.invalidateQueries("customers");
        history.push("/customers");
      } else alert(msg);
    } catch (err) {
      alert(err);
    }
    mounted.current && setSpinner(false);
  };

  return customer.data ? (
    <>
      <h2 className="mb-5 font-weight-light">{customer.data.namn}</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-5">
          <Customer {...customer.data} />
        </div>
        <Confirm spinner={spinner} label="Uppdatera" back={"/customers"} />
      </form>
    </>
  ) : null;
};

export default EditCustomer;
