import React, { useState } from "react";
import Confirm from "./Confirm.jsx";
import "./css/Products.scss";
import update from "immutability-helper";
import { send, sendForm } from "../lib/com.js";
import { useQuery, useQueryClient } from "react-query";

const Products = () => {
  const [user, setUser] = useState();

  const users = useQuery(["users", false], () => send("/admin/users"));

  const usr = user ?? users.data?.[0]?.id;

  const products = useQuery(
    ["products", usr],
    () => usr && send("/admin/products/" + usr)
  );

  const queryClient = useQueryClient();

  const submit = async (e) => {
    e.preventDefault();
    try {
      await sendForm("/admin/set-products/" + usr, e.target);
      queryClient.invalidateQueries(["products", usr]);
    } catch (err) {
      alert(err);
    }
  };

  const userChange = (e) => {
    setUser(e.target.value);
  };

  const addItem = () => {
    queryClient.setQueryData(
      ["products", usr],
      [
        ...products.data,
        {
          id: products.length
            ? products.reduce((max, e) => (e.id > max ? e.id : max), 0) + 1
            : 0,
        },
      ]
    );
  };

  const deleteItem = (i) => {
    queryClient.setQueryData(
      ["products", usr],
      update(products.data, { $splice: [[i, 1]] })
    );
  };

  return (
    <main className="product-list">
      <h2 className="mb-5 font-weight-light">Produkter</h2>

      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <label>Användare:</label>
              <select
                className="custom-select form-control mb-4"
                name="kund"
                id="select"
                required
                data-num
                onChange={userChange}
              >
                {users.data?.map(({ id, namn }) => (
                  <option key={namn} value={id}>
                    {namn}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {products.data ? (
          <table className="table table-borderless">
            <thead>
              <tr>
                <th>Art.nr</th>
                <th>Namn</th>
                <th>Antal ansl.</th>
                <th>Mex</th>
                <th>Pris exkl. moms</th>
                <th>Hyra exkl. moms</th>
                <th className="text-center">Ta bort</th>
              </tr>
            </thead>
            <tbody>
              {products.data.map(
                ({ id, artnr, namn, ansl, mex = false, pris, hyra }, i) => (
                  <tr key={id}>
                    <td className="align-baseline">
                      <input
                        className="form-control text-right"
                        type="number"
                        defaultValue={artnr}
                        required
                        name="artnr"
                        data-array
                      />
                    </td>
                    <td className="align-baseline">
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={namn}
                        required
                        name="namn"
                        data-array
                      />
                    </td>
                    <td className="align-baseline">
                      <input
                        className="form-control text-right"
                        type="number"
                        defaultValue={ansl}
                        required
                        name="ansl"
                        data-array
                      />
                    </td>
                    <td className="align-baseline text-center">
                      <input
                        type="checkbox"
                        name="mex"
                        defaultChecked={mex}
                        data-array
                      />
                    </td>
                    <td className="align-baseline">
                      <input
                        className="form-control text-right"
                        type="number"
                        name="pris"
                        defaultValue={pris}
                        required
                        data-array
                      ></input>
                    </td>
                    <td className="align-baseline">
                      <input
                        className="form-control text-right"
                        type="number"
                        name="hyra"
                        defaultValue={hyra}
                        required
                        data-array
                      ></input>
                    </td>
                    <td className="text-center align-baseline">
                      <button
                        className="slim-button"
                        type="button"
                        onClick={() => deleteItem(i)}
                      >
                        &times;
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : null}
        <button
          className="slim-button text-primary mb-3"
          type="button"
          onClick={addItem}
        >
          +
        </button>
        <div className="mt-5">
          <Confirm label="Spara" />
        </div>
        <p>
          <small>
            <i>Glöm inte att spara ändringar!</i>
          </small>
        </p>
      </form>
    </main>
  );
};

export default Products;
