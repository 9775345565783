import { send } from "../lib/com";
import React from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import { useQuery, useQueryClient } from "react-query";
import { useOptimistic } from "../lib/hooks";
import "./css/UserList.scss";

const UserList = () => {
  const queryClient = useQueryClient();

  const users = useQuery("users", () => send("/admin/users"));

  const del = useOptimistic(
    "users",
    (id) => send("/admin/delete-user/" + id),
    (old, _, i) => update(old, { $splice: [[i, 1]] })
  );

  const val = useOptimistic(
    "users",
    (id) => send("/admin/grant-user/" + id),
    (old, _, i) =>
      update(old, {
        [i]: { authorized: { $set: true } },
      })
  );

  const deleteUser = async (id, namn, i) => {
    if (window.confirm("Bekräfta för att ta bort " + namn))
      del(id, i).catch(alert);
  };

  const validate = (id, i) => {
    val(id, i).catch(alert);
  };

  const inactivate = async (id, i) => {
    try {
      const activation = await send("/admin/inactivate-user/" + id);
      queryClient.setQueryData(
        "users",
        update(users.data, {
          [i]: { activation: { $set: activation } },
        })
      );
      queryClient.invalidateQueries("users");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <main className="customer-list">
      <h2 className="mb-5 font-weight-light">Användare</h2>
      <nav>
        <ul className="ul list-group">
          {users.data?.map(({ namn, id, activation, authorized }, i) => (
            <li
              className="list-group-item d-flex mb-2 border-0 align-items-center"
              key={namn}
            >
              <Link className="customer flex-0" to={`/users/edit/${id}`}>
                {namn}
              </Link>
              <button
                className={`btn btn-${
                  authorized ? "success" : "primary"
                } ml-5 inactivate-user`}
                disabled={authorized}
                onClick={() => validate(id, i)}
              >
                <small className="font-weight-light">
                  {authorized ? "Validerad" : "Validera"}
                </small>
              </button>
              <button
                className="btn btn-secondary ml-2 inactivate-user"
                disabled={activation || !authorized}
                onClick={() => inactivate(id, i)}
              >
                {activation ? (
                  <span>
                    <small className="font-weight-light">Aktiveringkod: </small>
                    {activation}
                  </span>
                ) : (
                  <small className="font-weight-light">Inaktivera</small>
                )}
              </button>
              <button
                className="slim-button ml-auto"
                onClick={() => deleteUser(id, namn, i)}
              >
                &times;
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </main>
  );
};

export default UserList;
